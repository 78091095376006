<section>
  <div class="page">
    <div class="work-method-container">
      <div
        appIntersectionObserverAnimation
        alternativeThreshold="0"
        (isIntersecting)="onIntersection($event)"
        class="left-layout"
      >
        <header>
          <h2>
            {{ "work.title" | translate }}
            <span style="color: #93f2ff"
              >{{ "work.title-span" | translate }}
            </span>
          </h2>
          <p>
            {{ "work.subtitle-firstPart" | translate }}
            <span style="color: rgb(248, 246, 253)"
              >{{ "work.subtitle-span-1" | translate }}
            </span>
            {{ "work.subtitle-secondPart" | translate }}
            <span style="color: rgb(248, 246, 253)">{{
              "work.subtitle-span-2" | translate
            }}</span
            >{{ "work.subtitle-dot" | translate }}
          </p>
        </header>
        <a (click)="contact()" class="button-efect">
          {{ "work.button" | translate }}
          <svg
            class="arrow-right"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 12H5m14 0-4 4m4-4-4-4"
            />
          </svg>
        </a>
      </div>

      <section class="cards-container">
        <div
          appIntersectionObserverAnimation
          alternativeThreshold="0"
          (isIntersecting)="onIntersection($event)"
          class="card"
        >
          <div class="card-information">
            <header>
              <h3>{{ "work.card1-title" | translate }}</h3>
              <p>
                {{ "work.card1-description" | translate }}
              </p>
            </header>
          </div>
          <div class="card-image">
            <img
              src="/assets/images/how-we-work/planning-image.jpeg"
              alt="Planning image"
            />
          </div>
        </div>
        <div
          appIntersectionObserverAnimation
          (isIntersecting)="onIntersection($event)"
          class="card"
        >
          <div class="card-information">
            <header>
              <h3>{{ "work.card2-title" | translate }}</h3>
              <p>
                {{ "work.card2-description" | translate }}
              </p>
            </header>
          </div>
          <div class="card-image">
            <img
              src="/assets/images/how-we-work/design-icon.jpeg"
              alt="Design Icon"
            />
          </div>
        </div>
        <div
          appIntersectionObserverAnimation
          (isIntersecting)="onIntersection($event)"
          class="card"
        >
          <div class="card-information">
            <header>
              <h3>{{ "work.card3-title" | translate }}</h3>
              <p>
                {{ "work.card3-description" | translate }}
              </p>
            </header>
          </div>
          <div class="card-image">
            <img
              src="/assets/images/how-we-work/testing-icon.jpeg"
              alt="Design Icon"
            />
          </div>
        </div>
        <div
          appIntersectionObserverAnimation
          (isIntersecting)="onIntersection($event)"
          class="card"
        >
          <div class="card-information">
            <header>
              <h3>{{ "work.card4-title" | translate }}</h3>
              <p>
                {{ "work.card4-description" | translate }}
              </p>
            </header>
          </div>
          <div class="card-image">
            <img
              src="/assets/images/how-we-work/implementation-icon.jpeg"
              alt="Design Icon"
            />
          </div>
        </div>
        <div
          appIntersectionObserverAnimation
          (isIntersecting)="onIntersection($event)"
          class="card"
        >
          <div class="card-information">
            <header>
              <h3>{{ "work.card5-title" | translate }}</h3>
              <p>
                {{ "work.card5-description" | translate }}
              </p>
            </header>
          </div>
          <div class="card-image">
            <img
              src="/assets/images/how-we-work/maintenance-icon.jpeg"
              alt="Design Icon"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
