import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { InstitutionalComponent } from './pages/institutional/institutional.component';
import { CollectionsComponent } from './pages/collections/collections.component';
import { PricingComponent } from './pages/pricing/pricing.component';


const routes: Routes = [{ path: "", component: HomeComponent }, { path: "solutions", component: SolutionsComponent }, { path: "institutional", component: InstitutionalComponent }, { path: "pricing", component: PricingComponent }];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
