import { Component, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.css']
})
export class CustomerComponent {

    public isElementIntersecting: boolean = false;

    // Criacao do Array com todas as empresas que vão aparecer no slide
    public companies = [
        { name: "lottochain", link: "https://thelottochain.com/" },
        { name: "dillianz", link: "https://dillianzbank.com.br" },
        { name: "tokeniza", link: "https://plataforma.tokeniza.com.br/tokenization/crowdfunding" },
        { name: "LG-bank", link: "https://www.lgbank.io/" },
        { name: "vinco", link: "https://vinco.vc/" },
        { name: "zionex", link: "" },
        { name: "zeus-bank", link: "" },
        { name: "globoo", link: "" },
        { name: "tjrr", link: "https://www.tjrr.jus.br/" }
    ]

    @ViewChild('gallery')
    private gallery: ElementRef;
    private scrollInterval: any;

    constructor() { }

    public onIntersection(data: { element: HTMLElement, intersecting: boolean }) {
        this.isElementIntersecting = data.intersecting;
    }

    public contact(): void {
        window.open('https://wa.me/5512997758008', '_blank');
    }

    // embora a função esteja sendo chamada no HTML, ela está associada à classe CustomerComponent, mas não é uma parte direta da classe
    public startScrollRight(): void {
        this.scrollInterval = setInterval(() => {
            this.gallery.nativeElement.scrollLeft += 3; // Executa um bloco de código repetidamente com intervale fixo
        }, 12); //Intervale de tempo em que a repetição acontece
    }

    public stopScrollRight(): void {
        clearInterval(this.scrollInterval);
    }

    public startScrollLeft(): void {
        this.scrollInterval = setInterval(() => {
            this.gallery.nativeElement.scrollLeft -= 3; // Altere o valor de acordo com a velocidade de rolagem desejada
        }, 12);
    }

    public stopScrollLeft(): void {
        clearInterval(this.scrollInterval);
    }

}


