<section
  class="getting-started"
  appIntersectionObserverAnimation
  alternativeThreshold="0.25"
  (isIntersecting)="onIntersection($event)"
>
  <div class="container">
    <a href="" class="questions">
      <span>{{
        "pricing-questions.getting-started.questions" | translate
      }}</span>
      <span>{{
        "pricing-questions.getting-started.talk-to-expert" | translate
      }}</span>
    </a>
    <div class="explore-environment">
      <h2>{{ "pricing-questions.getting-started.start-using" | translate }}</h2>
      <p>
        {{
          "pricing-questions.getting-started.environment-description"
            | translate
        }}
      </p>
      <a href="https://midas-homolog.axiadigitalsolutions.net/account/login"
        >{{ "pricing-questions.getting-started.explore-button" | translate }}
        <svg
          _ngcontent-lyh-c23=""
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          class="arrow-right"
        >
          <path
            _ngcontent-lyh-c23=""
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 12H5m14 0-4 4m4-4-4-4"
          ></path>
        </svg>
      </a>
    </div>
  </div>
</section>
<section
  class="faq"
  appIntersectionObserverAnimation
  alternativeThreshold="0.25"
  (isIntersecting)="onIntersection($event)"
>
  <div class="faq-container">
    <div class="text">
      <h2>{{ "pricing-questions.getting-started.faq" | translate }}</h2>
    </div>
    <ul class="accordion-list">
      <li class="element" *ngFor="let question of questions; let i = index">
        <div class="question" (click)="toggleAnswer(i)">
          <h4 [ngStyle]="{ color: question.color }">
            {{ question.title | translate }}
          </h4>
          <span
            class="icon"
            [ngStyle]="{ backgroundColor: question.color }"
          ></span>
        </div>
        <div class="answer" [ngClass]="{ open: question.open }">
          <p>
            {{ question.answer | translate }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</section>
