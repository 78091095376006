import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions-hero',
    templateUrl: './solutions-hero.component.html',
    styleUrls: ['./solutions-hero.component.css']
})
export class SolutionsHeroComponent {

    constructor() { }

    public solutions = [
        { url: "/assets/svg/solutionsPage/polygon-matic-logo.svg", name: "polygon" },
        { url: "/assets/svg/solutionsPage/bitcoin-logo.svg", name: "bitcoin" },
        { url: "/assets/svg/solutionsPage/ethereum-logo.svg", name: "ethereum" },
        { url: "/assets/svg/solutionsPage/solana-logo.svg", name: "solana" },
        { url: "/assets/svg/solutionsPage/avalanche-logo.svg", name: "avalanche" },
        { url: "/assets/svg/solutionsPage/tron-logo.svg", name: "tron" },
        { url: "/assets/svg/solutionsPage/cardano-logo.svg", name: "cardano" },
        { url: "/assets/svg/solutionsPage/tether-logo.svg", name: "tether" },]


}
