<section class="down-section">
    <div class="first-section"
    appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <h2>{{ "collections-down.firstSectionTitle" | translate}}</h2>
        <p>{{ "collections-down.firstSectionText" | translate}}</p>
        <div class="improve-container">
            <div class="card">
                <div class="image">
                    <img src="/assets/svg/collectionsPage/dollar.svg">
                </div>
                <div class="content">
                    <h3>{{ "collections-down.cardTitleOne" | translate}}</h3>
                    <p>{{ "collections-down.cardTextOne" | translate}}</p>
                </div>
            </div>
            <div class="card">
                <div class="image">
                    <img src="/assets/svg/collectionsPage/wallet.svg">
                </div>
                <div class="content">
                    <h3>{{ "collections-down.cardTitleTwo" | translate}}</h3>
                    <p>{{ "collections-down.cardTextTwo" | translate}}</p>
                </div>
            </div>
            <div class="card">
                <div class="image">
                    <img src="/assets/svg/collectionsPage/ye-wallet.svg">
                </div>
                <div class="content">
                    <h3>{{ "collections-down.cardTitleThree" | translate}}</h3>
                    <p>{{ "collections-down.cardTextThree" | translate}}</p>
                </div>
            </div>
            <div class="card">
                <div class="image">
                    <img src="/assets/svg/collectionsPage/warning.svg">
                </div>
                <div class="content">
                    <h3>{{ "collections-down.cardTitleFour" | translate}}</h3>
                    <p>{{ "collections-down.cardTextFour" | translate}}</p>
                </div>
            </div>
            <div class="card">
                <div class="image">
                    <img src="/assets/svg/collectionsPage/document.svg">
                </div>
                <div class="content">
                    <h3>{{ "collections-down.cardTitleFive" | translate}}</h3>
                    <p>{{ "collections-down.cardTextFive" | translate}}</p>
                </div>
            </div>
            <div class="card">
                <div class="image">
                    <img src="/assets/svg/collectionsPage/square-warning.svg">
                </div>
                <div class="content-especial">
                    <h3>{{ "collections-down.cardTitleSix" | translate}}</h3>
                    <p>{{ "collections-down.cardTextSix" | translate}}</p>
                </div>
            </div>
            <div class="card">
                <div class="image">
                    <img src="/assets/svg/collectionsPage/dislike.svg">
                </div>
                <div class="content-especial">
                    <h3>{{ "collections-down.cardTitleSeven" | translate}}</h3>
                    <p>{{ "collections-down.cardTextSeven" | translate}}</p>
                </div>
            </div>
            <div class="all-line"></div>
        </div>
    </div>
    <div class="predictive"
    appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <h2>{{ "collections-down.predictiveTitle" | translate}}</h2>
        <p>{{ "collections-down.predictiveText" | translate}}
        </p>
        <img src="/assets/images/collectionsPage/share-data-removed.png">
    </div>
    <div class="customer-experience"
    appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <h2>{{ "collections-down.customerExperienceTitle" | translate}}</h2>
        <div class="experience-content">
        <div class="left-content">
            <p> {{ "collections-down.leftTextOne" | translate}}</p>
            <p>{{ "collections-down.leftTextTwo" | translate}}</p>
            <p>{{ "collections-down.leftTextThree" | translate}}</p>
            <p>{{ "collections-down.leftTextFour" | translate}}</p>
        </div>
        <div class="right-content">
                <p class="top-content">{{ "collections-down.topContent" | translate}}</p>
            <img src="/assets/images/collectionsPage/customers.png">
                <div class="bottom-content">
                    <h4>{{ "collections-down.bottomContentTitle" | translate}}</h4>
                <p>{{ "collections-down.bottomContentText" | translate}}</p>
                </div>
        </div>
    </div>
    </div>
    <div class="service"
    appIntersectionObserverAnimation
    (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <h2>{{ "collections-down.serviceTitle" | translate}}</h2>
        <p>{{ "collections-down.serviceTextOne" | translate}}</p>
            <p>{{ "collections-down.serviceTextTwo" | translate}}</p>
                <div class="down-row">
                    <div class="no-pay">
                        <h3>{{ "collections-down.noPayTitle" | translate}}</h3>
                        <p>{{ "collections-down.noPayText" | translate}}</p>
                    </div>
                    <div class="nopay-image">
                        <img src="/assets/images/collectionsPage/nopayno.png">
                    </div>
                </div>
    </div>
</section>