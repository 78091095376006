<section class="cover">
  <div class="banner">
    <div class="left-section-images">
      <div class="top-icon">
        <img class="normal-icon" src="/assets/images/institutionalPage/border-agenda.png" alt="Icon hero" />
      </div>
      <div class="middle-images-left">
        <div class="left-icon">
          <img class="normal-icon" src="/assets/images/hero/icon-hero-9-nobg.png" alt="Icon hero" />
        </div>
        <div class="right-icon">
          <img class="normal-icon" src="/assets/images/institutionalPage/border-assets.png" alt="Icon hero" />
        </div>
      </div>
      <div class="bottom-icon">
        <img class="normal-icon" src="/assets/images/institutionalPage/border-chat.png" alt="Icon hero" />
      </div>
    </div>
    <div class="middle-section">
      <h1 class="title">
        {{"institutional-cover.title" | translate}}
      </h1>
      <div class="subtitle">
        <p>
          {{"institutional-cover.subtitle" | translate}}
        </p>
      </div>
    </div>
    <div class="right-section-images">
      <div class="top-icon">
        <img class="normal-icon" src="/assets/images/institutionalPage/border-target.png" alt="Icon hero" />
      </div>
      <div class="middle-images-right">
        <div class="left-icon">
          <img class="big-icon" src="/assets/images/institutionalPage/border-chart.png" alt="Icon hero" />
        </div>
        <div class="right-icon">
          <img class="normal-icon" src="/assets/images/hero/icon-hero-9-nobg.png" alt="Icon hero" />
        </div>
      </div>
      <div class="bottom-icon">
        <img class="medium-big-icon" src="/assets/images/institutionalPage/border-lamp.png" alt="Icon hero" />
      </div>
    </div>
  </div>
  <div class="advantages-container">
    <div class="advantages-title">
      <h2>{{"institutional-cover.title" | translate}}</h2>
    </div>
    <div class="advantages-wrapper" #carouselWrapper>
      <ng-container *ngFor="let card of cards; let i = index">
        <div class="card" [ngClass]="{ 'middle-card': i === 2, 'even-card': i === 1 || i === 3 }">
          <div class="verification">
            <img src="/assets/svg/institutionalPage/verification.svg">
          </div>
          <div class="paragraph">
            <p>{{card.text | translate}}
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</section>