<section class="products">
  <div class="products-display">
    <div class="product">
      <div
        class="information-container-left"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <article>
          <h6>{{ "solutions-products.digital-wallet.product" | translate }}</h6>
          <h2>{{ "solutions-products.digital-wallet.title" | translate }}</h2>
          <div class="subtitle">
            <p>
              {{ "solutions-products.digital-wallet.subtitle" | translate }}
            </p>
          </div>
          <a
            href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+a+Carteira+Digital&type=custom_url&app_absent=0"
            target="_blank"
            >{{ "solutions-products.digital-wallet.button" | translate }}</a
          >
        </article>
      </div>
      <div
        class="image-container-right"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img src="/assets/images/solutionsPage/ipad.png" alt="Ipad" />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/digital-wallet.png"
              alt="Screenshot Digital Wallet Page"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div
        class="image-container-left"
        *ngIf="computer"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img src="/assets/images/solutionsPage/ipad.png" alt="Ipad" />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/marketplace.jpg"
              alt="Screenshot Marketplace"
            />
          </div>
        </div>
      </div>
      <div
        class="information-container-right"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <article>
          <h6>{{ "solutions-products.marketplace.product" | translate }}</h6>
          <h2>{{ "solutions-products.marketplace.title" | translate }}</h2>
          <div class="subtitle">
            <p>
              {{ "solutions-products.marketplace.subtitle" | translate }}
            </p>
          </div>
          <a
            href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+o+Marketplace&type=custom_url&app_absent=0"
            target="_blank"
            >{{ "solutions-products.marketplace.button" | translate }}</a
          >
        </article>
      </div>
      <div
        class="image-container-left"
        *ngIf="mobile"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img src="/assets/images/solutionsPage/ipad.png" alt="Ipad" />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/marketplace.jpg"
              alt="Screenshot Marketplace"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div
        class="information-container-left"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <article>
          <h6>
            {{ "solutions-products.digital-banking.product" | translate }}
          </h6>
          <h2>{{ "solutions-products.digital-banking.title" | translate }}</h2>
          <div class="subtitle">
            <p>
              {{ "solutions-products.digital-banking.subtitle" | translate }}
            </p>
          </div>
          <a
            href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+o+Banco+Digital&type=custom_url&app_absent=0"
            target="_blank"
            >{{ "solutions-products.digital-banking.button" | translate }}</a
          >
        </article>
      </div>
      <div
        class="image-container-right"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img
            src="/assets/images/solutionsPage/ipad.png"
            alt="Screenshot Digital Wallet Page"
          />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/digital-banking.png"
              alt="Screenshot Digital Wallet Page"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div
        class="image-container-left"
        *ngIf="computer"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img src="/assets/images/solutionsPage/ipad.png" alt="Ipad" />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/tokenization.jpg"
              alt="Screenshot Marketplace"
            />
          </div>
        </div>
      </div>
      <div
        class="information-container-right"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <article>
          <h6>{{ "solutions-products.tokenization.product" | translate }}</h6>
          <h2>{{ "solutions-products.tokenization.title" | translate }}</h2>
          <div class="subtitle">
            <p>
              {{ "solutions-products.tokenization.subtitle" | translate }}
            </p>
          </div>
          <a
            href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+Tokenização+e+NFTs&type=custom_url&app_absent=0"
            target="_blank"
            >{{ "solutions-products.tokenization.button" | translate }}</a
          >
        </article>
      </div>
      <div
        class="image-container-left"
        *ngIf="mobile"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img src="/assets/images/solutionsPage/ipad.png" alt="Ipad" />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/tokenization.jpg"
              alt="Screenshot Marketplace"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div
        class="information-container-left"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <article>
          <h6>{{ "solutions-products.gateway.product" | translate }}</h6>
          <h2>{{ "solutions-products.gateway.title" | translate }}</h2>
          <div class="subtitle">
            <p>
              {{ "solutions-products.gateway.subtitle" | translate }}
            </p>
          </div>
          <a
            href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+o+Gateway+de+Pagamentos+Crypto&type=custom_url&app_absent=0"
            target="_blank"
            >{{ "solutions-products.gateway.button" | translate }}</a
          >
        </article>
      </div>
      <div
        class="image-container-right"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img
            src="/assets/images/solutionsPage/ipad.png"
            alt="Screenshot Digital Wallet Page"
          />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/gateway.png"
              alt="Screenshot Digital Wallet Page"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div
        class="image-container-left"
        *ngIf="computer"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img
            src="/assets/images/solutionsPage/ipad.png"
            alt="Screenshot Digital Wallet Page"
          />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/crowdfunding.png"
              alt="Screenshot Digital Wallet Page"
            />
          </div>
        </div>
      </div>
      <div
        class="information-container-right"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <article>
          <h6>{{ "solutions-products.crowdfunding.product" | translate }}</h6>
          <h2>{{ "solutions-products.crowdfunding.title" | translate }}</h2>
          <div class="subtitle">
            <p>
              {{ "solutions-products.crowdfunding.subtitle" | translate }}
            </p>
          </div>
          <a
            href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+Crowdfunding&type=custom_url&app_absent=0"
            target="_blank"
            >{{ "solutions-products.crowdfunding.button" | translate }}</a
          >
        </article>
      </div>
      <div
        class="image-container-left"
        *ngIf="mobile"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img
            src="/assets/images/solutionsPage/ipad.png"
            alt="Screenshot Digital Wallet Page"
          />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/crowdfunding.png"
              alt="Screenshot Digital Wallet Page"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div
        class="information-container-left"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <article>
          <h6>{{ "solutions-products.exchange.product" | translate }}</h6>
          <h2>
            {{ "solutions-products.exchange.title" | translate }}
          </h2>
          <div class="subtitle">
            <p>
              {{ "solutions-products.exchange.subtitle" | translate }}
            </p>
          </div>
          <a
            href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+o+Exchange&type=custom_url&app_absent=0"
            target="_blank"
            >{{ "solutions-products.exchange.button" | translate }}</a
          >
        </article>
      </div>
      <div
        class="image-container-right"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
      >
        <div class="ipad">
          <img
            src="/assets/images/solutionsPage/ipad.png"
            alt="Screenshot Digital Wallet Page"
          />
          <div class="image-overlay-ipad">
            <img
              src="/assets/images/solutionsPage/exchange.png"
              alt="Screenshot Digital Wallet Page"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
