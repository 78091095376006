import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {
 
    if (data.intersecting && !data.element.classList.contains("scrolled")) {
        data.element.classList.add("scrolled");
    }
  
  }

}
