<section class="display">
  <a
    href="https://wa.me/5512997758008?text=Olá!+Eu+gostaria+de+conhecer+mais+sobre+o+Ecossistema+da+Axia&type=custom_url&app_absent=0"
    target="_blank"
    class="card"
  >
    <h6>{{ "solutions-test.subheading" | translate }}</h6>
    <h2>
      {{ "solutions-test.title" | translate }}
    </h2>
    <a>{{ "solutions-test.button" | translate }}</a>
  </a>
</section>
