import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-prices',
    templateUrl: './prices.component.html',
    styleUrls: ['./prices.component.css']
})
export class PricesComponent implements AfterViewInit {

    @ViewChild('gallery') galleryRef: ElementRef;
    @ViewChild('card') cardRef: ElementRef;

    public currentSlide: number = 0; // curent slide we are seeing 
    public isDiscountActivated: boolean = false;
    public showForm: boolean = false;
    public formSolution: string | null = null;
    public solutions: Array<string> = ["Infrastructure", "Whitelabel", "Cronos (RPA)"]
    public solutionSelected: string = this.solutions[0];
    private screenSize: number = window.innerWidth;
    private slidesNumber: number | null = null;

    public infraAspects = [
        {
            img: "/assets/images/pricingPage/scalability-icon.png", alt: "Scalability icon", description: this.translateService.instant("pricing-prices.solutions.infrastructure.first-feature")
        },
        {
            img: "/assets/images/pricingPage/security-icon.png", alt: "Security icon", description: this.translateService.instant("pricing-prices.solutions.infrastructure.second-feature")
        },
        {
            img: "assets/images/pricingPage/interoperability-icon.png", alt: "Interoperability icon", description: this.translateService.instant("pricing-prices.solutions.infrastructure.third-feature")
        },
        {
            img: "assets/images/pricingPage/management-icon.png", alt: "Simplified management icon", description: this.translateService.instant("pricing-prices.solutions.infrastructure.forth-feature")
        },
    ]

    public infraCards = [
        {
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.first-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.first-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.first-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.first-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.infrastructure.first-plan-customer"),
            wallets: "250",
            requests: "12,000",
            EVM: 30,
            tradeFee: "1.2%",
            businessHours: "80",
            firstFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.first-feature-included"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.second-feature-included"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.third-feature-included"),
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.second-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.second-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.second-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.second-plan-annual-price"),
            wallets: "500",
            requests: "24,000",
            EVM: 30,
            tradeFee: "1.2%",
            businessHours: "80",
            customerDescription: this.translateService.instant("pricing-prices.solutions.infrastructure.second-plan-customer"),
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.third-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.third-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.third-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.infrastructure.third-plan-annual-price"),
            wallets: "1000",
            requests: "48,000",
            EVM: 30,
            tradeFee: "1%",
            businessHours: "40",
            customerDescription: this.translateService.instant("pricing-prices.solutions.infrastructure.third-plan-customer"),

        },
        {
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.forth-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.common.custom"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.common.custom"),
            anualPrice: "",
            wallets: this.translateService.instant("pricing-prices.solutions.infrastructure.unlimited-wallets"),
            requests: this.translateService.instant("pricing-prices.solutions.infrastructure.unlimited-requests"),
            EVM: 30,
            tradeFee: "0.7%",
            businessHours: "24",
            customerDescription: this.translateService.instant("pricing-prices.solutions.infrastructure.third-plan-customer"),

        },]

    public infraFeatures = [
        {
            img: "/assets/images/pricingPage/smart-contracts.png",
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.first-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.first-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.first-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.first-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/tokenization.png",
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.second-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.second-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.second-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.second-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/audit.png",
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.third-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.third-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.third-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.third-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/api.png",
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-3"),
        },
    ]

    public whitelabelAspects = [
        {
            img: "/assets/images/pricingPage/personalized-interface.png", alt: "Personalized Interface icon", description: this.translateService.instant("pricing-prices.solutions.whitelabel.first-feature")
        },
        {
            img: "/assets/images/pricingPage/fast-launching(1).png", alt: "Fast Launching icon", description: this.translateService.instant("pricing-prices.solutions.whitelabel.second-feature")
        },
        {
            img: "assets/images/pricingPage/computer-cryptography.png", alt: "Computer Cryptography icon", description: this.translateService.instant("pricing-prices.solutions.whitelabel.third-feature")
        },
        {
            img: "assets/images/pricingPage/computer-support.png", alt: "Computer Support icon", description: this.translateService.instant("pricing-prices.solutions.whitelabel.forth-feature")
        },
    ]

    public whitelabelCards = [
        {
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.first-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.first-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.first-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.first-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.whitelabel.first-plan-description"),
            features: [
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalWallet-feature1"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalWallet-feature2"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalWallet-feature3"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalWallet-feature4"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalWallet-feature5"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalWallet-feature6"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalWallet-feature7"),
            ]
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.fifth-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.second-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.second-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.second-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.whitelabel.fifth-plan-description"),
            features: [
                this.translateService.instant("pricing-prices.solutions.whitelabel.exchange-feature1"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.exchange-feature2"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.exchange-feature3"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.exchange-feature4"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.exchange-feature5"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.exchange-feature6"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.exchange-feature7"),
            ]
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.third-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.third-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.third-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.third-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.whitelabel.third-plan-description"),
            features: [
                this.translateService.instant("pricing-prices.solutions.whitelabel.crowdfunding-feature1"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.crowdfunding-feature2"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.crowdfunding-feature3"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.crowdfunding-feature4"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.crowdfunding-feature5"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.crowdfunding-feature6"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.crowdfunding-feature7"),
            ]
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.forth-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.forth-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.forth-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.forth-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.whitelabel.forth-plan-description"),
            features: [
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalBanking-feature1"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalBanking-feature2"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalBanking-feature3"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalBanking-feature4"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalBanking-feature5"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalBanking-feature6"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.digitalBanking-feature7"),
            ]
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.second-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.fifth-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.fifth-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.whitelabel.fifth-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.whitelabel.second-plan-description"),
            features: [
                this.translateService.instant("pricing-prices.solutions.whitelabel.tokenization-feature1"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.tokenization-feature2"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.tokenization-feature3"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.tokenization-feature4"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.tokenization-feature5"),
                this.translateService.instant("pricing-prices.solutions.whitelabel.tokenization-feature6"),
            ]
        },
    ]

    public whitelabelFeatures = [
        {
            img: "/assets/images/pricingPage/report.png",
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.first-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.first-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.first-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.first-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/user-interface.png",
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.second-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.second-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.second-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.second-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/identity.png",
            name: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.third-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.third-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.third-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.whitelabel.plan-details.third-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/api.png",
            name: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.infrastructure.plan-details.forth-feature-3"),
        },
    ]

    public cronosAspects = [
        {
            img: "/assets/images/pricingPage/billing-automation.png", alt: "Billing Automation icon", description: this.translateService.instant("pricing-prices.solutions.cronos.first-feature")
        },
        {
            img: "/assets/images/pricingPage/analysis.png", alt: "Analysis icon", description: this.translateService.instant("pricing-prices.solutions.cronos.second-feature")
        },
        {
            img: "assets/images/pricingPage/computer-notifications.png", alt: "Notifications icon", description: this.translateService.instant("pricing-prices.solutions.cronos.third-feature")
        },
        {
            img: "assets/images/pricingPage/dashboard.png", alt: "Organization icon", description: this.translateService.instant("pricing-prices.solutions.cronos.forth-feature")
        },
    ]

    public cronosCards = [
        {
            name: this.translateService.instant("pricing-prices.solutions.cronos.first-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.cronos.first-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.cronos.first-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.cronos.first-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.cronos.first-plan-customer"),
            features: [
                this.translateService.instant("pricing-prices.solutions.cronos.plus-feature1"),
                this.translateService.instant("pricing-prices.solutions.cronos.plus-feature2"),
                this.translateService.instant("pricing-prices.solutions.cronos.plus-feature3"),
                this.translateService.instant("pricing-prices.solutions.cronos.plus-feature4"),
                this.translateService.instant("pricing-prices.solutions.cronos.plus-feature5"),
                this.translateService.instant("pricing-prices.solutions.cronos.plus-feature6"),
            ]
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.cronos.second-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.cronos.second-plan-price"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.cronos.second-plan-discount-price"),
            anualPrice: this.translateService.instant("pricing-prices.solutions.cronos.second-plan-annual-price"),
            customerDescription: this.translateService.instant("pricing-prices.solutions.cronos.second-plan-customer"),
            features: [
                this.translateService.instant("pricing-prices.solutions.cronos.pro-feature1"),
                this.translateService.instant("pricing-prices.solutions.cronos.pro-feature2"),
                this.translateService.instant("pricing-prices.solutions.cronos.pro-feature3"),
                this.translateService.instant("pricing-prices.solutions.cronos.pro-feature4"),
                this.translateService.instant("pricing-prices.solutions.cronos.pro-feature5"),
                this.translateService.instant("pricing-prices.solutions.cronos.pro-feature6"),
                this.translateService.instant("pricing-prices.solutions.cronos.pro-feature7"),
            ]
        },
        {
            name: this.translateService.instant("pricing-prices.solutions.cronos.third-plan-name"),
            regularPrice: this.translateService.instant("pricing-prices.solutions.common.custom"),
            discountPrice: this.translateService.instant("pricing-prices.solutions.common.custom"),
            anualPrice: "",
            customerDescription: this.translateService.instant("pricing-prices.solutions.cronos.third-plan-customer"),
            features: [
                this.translateService.instant("pricing-prices.solutions.cronos.enterprise-feature1"),
                this.translateService.instant("pricing-prices.solutions.cronos.enterprise-feature2"),
                this.translateService.instant("pricing-prices.solutions.cronos.enterprise-feature3"),
                this.translateService.instant("pricing-prices.solutions.cronos.enterprise-feature4"),
                this.translateService.instant("pricing-prices.solutions.cronos.enterprise-feature5"),
                this.translateService.instant("pricing-prices.solutions.cronos.enterprise-feature6"),
                this.translateService.instant("pricing-prices.solutions.cronos.enterprise-feature7"),
            ]
        },
    ]

    public cronosFeatures = [
        {
            img: "/assets/images/pricingPage/timeline.png",
            name: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.first-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.first-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.first-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.first-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/business-flow.png",
            name: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.second-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.second-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.second-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.second-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/business-unit.png",
            name: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.third-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.third-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.third-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.third-feature-3"),
        },
        {
            img: "/assets/images/pricingPage/task-configuration.png",
            name: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.forth-feature-title"),
            firstFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.forth-feature-1"),
            secondFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.forth-feature-2"),
            thirdFeature: this.translateService.instant("pricing-prices.solutions.cronos.plan-details.forth-feature-3"),
        },
    ]


    constructor(private readonly translateService: TranslateService) { }

    public ngAfterViewInit(): void {
        this.checkScreenSize();
    }

    public checkScreenSize(): void {
        if (window.innerWidth > 1439) {
            this.slidesNumber = 2;
        } else if (window.innerWidth < 1439 && window.innerWidth > 920) {
            this.slidesNumber = 1;
        } else {
            this.slidesNumber = 0;
        }
    }

    public activateDiscount(): void {
        this.isDiscountActivated = !this.isDiscountActivated;
    }

    public nextSlide(): void {
        if (this.currentSlide < this.infraCards.length - this.slidesNumber) {
            this.currentSlide++;
            this.updateSlidePosition();
        }
    }

    public prevSlide(): void {
        if (this.currentSlide > 0) {
            this.currentSlide--;
            this.updateSlidePosition();
        }
    }

    private updateSlidePosition(): void {
        const gallery = this.galleryRef.nativeElement;
        const cardWidth = this.cardRef.nativeElement.clientWidth;
        const offset = this.currentSlide * (cardWidth + 32);
        gallery.style.transform = `translateX(-${offset}px)`;
    }

    public toggleForm(solution?: number | null): void {
        this.showForm = !this.showForm;
        if (solution === 0) {
            this.formSolution = this.translateService.instant("pricing-prices.solutions.infrastructure.name")
        } else if (solution === 1) {
            this.formSolution = this.translateService.instant("pricing-prices.solutions.whitelabel.name")
        } else {
            this.formSolution = this.translateService.instant("pricing-prices.solutions.cronos.name")
        }
    }

    public onSubmit(form: NgForm) {
        if (form.invalid) {
            form.control.markAllAsTouched();
        } else {
            const formData = form.value;
            const number = 5512997758008
            const message = `
            Personal Details:

            Name: ${formData.givenName} ${formData.lastName} 
            Email: ${formData.email} 
            Company: ${formData.company} 
            Job Title: ${formData.job} 
            Business Segment: ${formData.segment} 
            
            Product Informations:
            
            Solution: ${formData.solution} 
            Package: ${formData.package} 
            Billing: ${formData.billing} 
            `
            const encodedMessage = encodeURIComponent(message.trim());
            const url = `https://api.whatsapp.com/send/?phone=${number}&text=${encodedMessage}`

            window.open(url, "_blank");
        }
    }

    public selectSolution(index: number): void {
        this.solutionSelected = this.solutions[index];
    }

    public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {

        if (data.intersecting && !data.element.classList.contains("animation")) {
            data.element.classList.add("animation");
        }

    }

}
