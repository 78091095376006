<section class="header-container">
  <header>
    <h6>{{ "pricing-prices.subheading" | translate }}</h6>
    <h1>
      {{ "pricing-prices.title" | translate }}
    </h1>
  </header>
</section>
<section class="solutions-pricing">
  <div class="plans-container">
    <h2>{{ "pricing-prices.selection" | translate }}</h2>
    <div
      class="pricing-nav"
      appIntersectionObserverAnimation
      alternativeThreshold="0.25"
      (isIntersecting)="onIntersection($event)"
    >
      <ul>
        <li class="solution">
          <button
            [class.active]="solutionSelected === this.solutions[0]"
            (click)="selectSolution(0)"
          >
            {{ "pricing-prices.solutions.infrastructure.name" | translate }}
          </button>
        </li>
        <li class="solution">
          <button
            [class.active]="solutionSelected === this.solutions[1]"
            (click)="selectSolution(1)"
          >
            {{ "pricing-prices.solutions.whitelabel.name" | translate }}
          </button>
        </li>
        <!-- <li class="solution">
          <button
            [class.active]="solutionSelected === this.solutions[2]"
            (click)="selectSolution(2)"
          >
            {{ "pricing-prices.solutions.cronos.name" | translate }}
          </button>
        </li> -->
      </ul>
    </div>
    <ul>
      <li
        *ngIf="solutionSelected === this.solutions[0]"
        appIntersectionObserverAnimation
        alternativeThreshold="0.1"
        (isIntersecting)="onIntersection($event)"
      >
        <ul class="plan-feature">
          <ng-container *ngFor="let aspect of infraAspects; let i = index">
            <li>
              <div class="image-container">
                <img
                  [src]="aspect.img"
                  [alt]="aspect.alt"
                  [ngClass]="{ security: i === 1 }"
                />
              </div>
              <div class="feature-description">
                <p>
                  {{ aspect.description | translate }}
                </p>
              </div>
            </li>
          </ng-container>
        </ul>
        <div class="plan-price">
          <div class="gallery infrastructure" #gallery>
            <ng-container *ngFor="let card of infraCards; let i = index">
              <div class="card infrastructure" #card>
                <div
                  class="pricing-content active"
                  [ngClass]="{ active: isDiscountActivated }"
                >
                  <article>
                    <h3>{{ card.name | translate }}</h3>
                    <div class="annual-discount" *ngIf="i !== 3">
                      <span class="discount-text">{{
                        "pricing-prices.solutions.common.annual" | translate
                      }}</span>
                      <span class="discount-percentage">
                        <span class="discount">-5%</span>
                      </span>
                      <button
                        class="discount-toggle"
                        (click)="activateDiscount()"
                      ></button>
                    </div>
                  </article>
                  <div class="price-container">
                    <div class="price">
                      <span class="currency" *ngIf="i !== 3">{{
                        "pricing-prices.solutions.common.currency" | translate
                      }}</span>
                      <span
                        class="regular-price"
                        *ngIf="!isDiscountActivated"
                        >{{ card.regularPrice | translate }}</span
                      >
                      <span
                        class="discount-price"
                        *ngIf="isDiscountActivated"
                        >{{ card.discountPrice | translate }}</span
                      >
                      <span class="interval" *ngIf="i !== 3">{{
                        "pricing-prices.solutions.common.month" | translate
                      }}</span>
                    </div>
                  </div>
                  <p class="customer-description">
                    {{ card.customerDescription | translate }}
                  </p>
                  <div class="contact-container">
                    <a href="javascript:void(0)" (click)="toggleForm(0)"
                      >{{ "pricing-prices.solutions.common.start" | translate }}
                      <img
                        src="/assets/svg/right-arrow-white.svg"
                        alt="Right Arrow"
                      />
                    </a>
                  </div>
                  <div class="total-price">
                    <span
                      class="toggle-off-text"
                      *ngIf="i !== 3 && !isDiscountActivated"
                      >{{
                        "pricing-prices.solutions.common.total-cost-monthly"
                          | translate
                      }}{{ card.regularPrice | translate }}</span
                    >
                    <span
                      class="toggle-on-text"
                      *ngIf="i !== 3 && isDiscountActivated"
                      >{{
                        "pricing-prices.solutions.common.total-cost-annual"
                          | translate
                      }}{{ card.anualPrice | translate }}</span
                    >
                  </div>
                </div>
                <ul class="overview">
                  <li class="highlighted">
                    <p>
                      {{
                        "pricing-prices.solutions.common.includes" | translate
                      }}
                    </p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>
                      <span>{{ card.wallets | translate }}</span>
                      wallets
                    </p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>
                      <span>{{ card.requests | translate }}</span>
                      requests
                    </p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>
                      <span>{{ card.EVM | translate }}</span>
                      EVM
                    </p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>
                      <span>{{ card.tradeFee | translate }}</span>
                      trade fee
                    </p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>
                      <span>{{ card.businessHours | translate }}</span>
                      {{
                        "pricing-prices.solutions.infrastructure.second-feature-included"
                          | translate
                      }}
                    </p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>NFTs</p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>Tokens</p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>Staking (0.1% Fee)</p>
                  </li>
                  <li>
                    <img
                      src="/assets/svg/pricingPage/checkbox.svg"
                      alt="Circle Checkbox "
                    />
                    <p>
                      {{
                        "pricing-prices.solutions.infrastructure.third-feature-included"
                          | translate
                      }}
                      (0.1% Fee)
                    </p>
                  </li>
                  <li class="footnote" *ngIf="i !== 3">
                    <p>
                      {{
                        "pricing-prices.solutions.common.footnote" | translate
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="plan-details">
          <h3>{{ "pricing-prices.solutions.common.included" | translate }}</h3>
          <div class="details-informations-container">
            <ng-container *ngFor="let feature of infraFeatures; let i = index">
              <div class="feature-card">
                <div class="image-container">
                  <img [src]="feature.img" alt="Aqui tem um ícone" />
                </div>
                <h4>{{ feature.name | translate }}</h4>
                <div class="bullet-list">
                  <ul>
                    <li>
                      <span>{{ feature.firstFeature | translate }}</span>
                    </li>
                    <li>
                      <span>{{ feature.secondFeature | translate }}</span>
                    </li>
                    <li>
                      <span>{{ feature.thirdFeature | translate }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </li>
      <li
        *ngIf="solutionSelected === this.solutions[1]"
        appIntersectionObserverAnimation
        alternativeThreshold="0.1"
        (isIntersecting)="onIntersection($event)"
      >
        <ul class="plan-feature">
          <ng-container *ngFor="let aspect of whitelabelAspects; let i = index">
            <li>
              <div class="image-container">
                <img
                  [src]="aspect.img"
                  [alt]="aspect.alt"
                  [ngClass]="{ 'smaller-image-support': i === 3 }"
                />
              </div>
              <div class="feature-description">
                <p>
                  {{ aspect.description | translate }}
                </p>
              </div>
            </li>
          </ng-container>
        </ul>
        <div class="plan-price-container">
          <img
            (click)="prevSlide()"
            src="/assets/svg/pricingPage/blue-left-arrow.svg"
            alt="Previous Image"
          />
          <img
            (click)="nextSlide()"
            src="/assets/svg/pricingPage/blue-right-arrow.svg"
            alt="Next Image"
          />
          <div class="plan-price whitelabel">
            <div class="gallery-wrapper">
              <div class="gallery whitelabel" #gallery>
                <ng-container
                  *ngFor="let card of whitelabelCards; let i = index"
                >
                  <div class="card whitelabel" #card>
                    <div
                      class="pricing-content active"
                      [ngClass]="{ active: isDiscountActivated }"
                    >
                      <article>
                        <h3 [ngClass]="{ 'break-word': i == 2 || i == 4 }">
                          {{ card.name | translate }}
                        </h3>
                        <div class="annual-discount">
                          <span class="discount-text">{{
                            "pricing-prices.solutions.common.annual" | translate
                          }}</span>
                          <span class="discount-percentage">
                            <span class="discount">-5%</span>
                          </span>
                          <button
                            class="discount-toggle"
                            (click)="activateDiscount()"
                          ></button>
                        </div>
                      </article>
                      <div class="price-container">
                        <div class="price">
                          <span class="currency">{{
                            "pricing-prices.solutions.common.currency"
                              | translate
                          }}</span>
                          <span
                            class="regular-price"
                            *ngIf="!isDiscountActivated"
                            >{{ card.regularPrice | translate }}</span
                          >
                          <span
                            class="discount-price"
                            *ngIf="isDiscountActivated"
                            >{{ card.discountPrice | translate }}</span
                          >
                          <span class="interval"
                            >{{
                              "pricing-prices.solutions.common.month"
                                | translate
                            }}
                            <span>²</span>
                          </span>
                        </div>
                      </div>
                      <p class="customer-description">
                        {{ card.customerDescription | translate }}
                      </p>
                      <div class="contact-container">
                        <a href="javascript:void(0)" (click)="toggleForm(1)"
                          >{{
                            "pricing-prices.solutions.common.start" | translate
                          }}
                          <img
                            src="/assets/svg/right-arrow-white.svg"
                            alt="Right Arrow"
                          />
                        </a>
                      </div>
                      <div class="total-price">
                        <span
                          class="toggle-off-text"
                          *ngIf="!isDiscountActivated"
                          >{{
                            "pricing-prices.solutions.common.total-cost-monthly"
                              | translate
                          }}{{ card.regularPrice | translate }}</span
                        >
                        <span class="toggle-on-text" *ngIf="isDiscountActivated"
                          >{{
                            "pricing-prices.solutions.common.total-cost-annual"
                              | translate
                          }}{{ card.anualPrice | translate }}</span
                        >
                      </div>
                    </div>
                    <ul class="overview">
                      <li class="highlighted">
                        <p>
                          {{
                            "pricing-prices.solutions.common.includes"
                              | translate
                          }}
                        </p>
                      </li>
                      <ng-container *ngFor="let feature of card.features">
                        <li>
                          <img
                            src="/assets/svg/pricingPage/checkbox.svg"
                            alt="Circle Checkbox "
                          />
                          <p>
                            {{ feature | translate }}
                          </p>
                        </li>
                      </ng-container>
                      <li class="footnote">
                        <p>
                          {{
                            "pricing-prices.solutions.common.footnote"
                              | translate
                          }}
                        </p>
                      </li>
                      <li class="footnote2">
                        <p>
                          {{
                            "pricing-prices.solutions.common.footnote2"
                              | translate
                          }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="plan-details">
          <h3>{{ "pricing-prices.solutions.common.included" | translate }}</h3>
          <div class="details-informations-container">
            <ng-container
              *ngFor="let feature of whitelabelFeatures; let i = index"
            >
              <div class="feature-card">
                <div class="image-container">
                  <img
                    [src]="feature.img"
                    alt="Aqui tem um ícone"
                    [ngClass]="{ 'smaller-image': i === 1 }"
                  />
                </div>
                <h4>{{ feature.name | translate }}</h4>
                <div class="bullet-list">
                  <ul>
                    <li>
                      <span>{{ feature.firstFeature | translate }}</span>
                    </li>
                    <li>
                      <span>{{ feature.secondFeature | translate }}</span>
                    </li>
                    <li>
                      <span>{{ feature.thirdFeature | translate }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </li>
      <!-- <li
        *ngIf="solutionSelected === this.solutions[2]"
        appIntersectionObserverAnimation
        alternativeThreshold="0.1"
        (isIntersecting)="onIntersection($event)"
      >
        <ul class="plan-feature">
          <ng-container *ngFor="let aspect of cronosAspects; let i = index">
            <li>
              <div class="image-container">
                <img [src]="aspect.img" [alt]="aspect.alt" />
              </div>
              <div class="feature-description">
                <p>
                  {{ aspect.description | translate }}
                </p>
              </div>
            </li>
          </ng-container>
        </ul>
        <div class="plan-price">
          <div class="gallery cronos" #gallery>
            <ng-container *ngFor="let card of cronosCards; let i = index">
              <div class="card cronos" #card>
                <div
                  class="pricing-content active"
                  [ngClass]="{ active: isDiscountActivated }"
                >
                  <article>
                    <h3>{{ card.name | translate }}</h3>
                    <div class="annual-discount" *ngIf="i !== 2">
                      <span class="discount-text">{{
                        "pricing-prices.solutions.common.annual" | translate
                      }}</span>
                      <span class="discount-percentage">
                        <span class="discount">-5%</span>
                      </span>
                      <button
                        class="discount-toggle"
                        (click)="activateDiscount()"
                      ></button>
                    </div>
                  </article>
                  <div class="price-container">
                    <div class="price">
                      <span class="currency" *ngIf="i !== 2">{{
                        "pricing-prices.solutions.common.currency" | translate
                      }}</span>
                      <span
                        class="regular-price"
                        *ngIf="!isDiscountActivated"
                        >{{ card.regularPrice | translate }}</span
                      >
                      <span
                        class="discount-price"
                        *ngIf="isDiscountActivated"
                        >{{ card.discountPrice | translate }}</span
                      >
                      <span class="interval" *ngIf="i !== 2"
                        >{{
                          "pricing-prices.solutions.cronos.user-month"
                            | translate
                        }}
                      </span>
                    </div>
                  </div>
                  <p class="customer-description">
                    {{ card.customerDescription | translate }}
                  </p>
                  <div class="contact-container">
                    <a href="javascript:void(0)" (click)="toggleForm(2)"
                      >{{ "pricing-prices.solutions.common.start" | translate }}
                      <img
                        src="/assets/svg/right-arrow-white.svg"
                        alt="Right Arrow"
                      />
                    </a>
                  </div>
                  <div class="total-price" *ngIf="i !== 2">
                    <span class="toggle-off-text" *ngIf="!isDiscountActivated"
                      >{{
                        "pricing-prices.solutions.common.total-cost-monthly"
                          | translate
                      }}{{ card.regularPrice | translate }}</span
                    >
                    <span class="toggle-on-text" *ngIf="isDiscountActivated"
                      >{{
                        "pricing-prices.solutions.common.total-cost-annual"
                          | translate
                      }}{{ card.anualPrice | translate }}</span
                    >
                  </div>
                </div>
                <ul class="overview">
                  <li class="highlighted">
                    <p>
                      {{
                        "pricing-prices.solutions.common.includes" | translate
                      }}
                    </p>
                  </li>
                  <ng-container *ngFor="let feature of card.features">
                    <li>
                      <img
                        src="/assets/svg/pricingPage/checkbox.svg"
                        alt="Circle Checkbox "
                      />
                      <p>
                        {{ feature | translate }}
                      </p>
                    </li>
                  </ng-container>
                  <li class="footnote">
                    <p>
                      {{
                        "pricing-prices.solutions.common.footnote" | translate
                      }}
                    </p>
                  </li>
                  <li class="footnote2">
                    <p>
                      {{
                        "pricing-prices.solutions.common.footnote2-cronos"
                          | translate
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="plan-details">
          <h3>{{ "pricing-prices.solutions.common.included" | translate }}</h3>
          <div class="details-informations-container">
            <ng-container *ngFor="let feature of cronosFeatures; let i = index">
              <div class="feature-card">
                <div class="image-container">
                  <img [src]="feature.img" alt="Aqui tem um ícone" />
                </div>
                <h4>{{ feature.name | translate }}</h4>
                <div class="bullet-list">
                  <ul>
                    <li>
                      <span>{{ feature.firstFeature | translate }}</span>
                    </li>
                    <li>
                      <span>{{ feature.secondFeature | translate }}</span>
                    </li>
                    <li>
                      <span>{{ feature.thirdFeature | translate }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </li> -->
    </ul>
    <!-- Pop-up Form -->
    <div class="popup-form-overlay" *ngIf="showForm">
      <div class="contact-form">
        <img
          src="/assets/images/pricingPage/close-button.png"
          alt="Close button"
          class="close-button"
          (click)="toggleForm()"
        />
        <div class="content">
          <a class="cancel-button" (click)="toggleForm()">{{
            "pricing-prices.solutions.common.forms.cancel" | translate
          }}</a>
          <div class="left-layout">
            <header>
              <h2>
                {{ "pricing-prices.solutions.common.forms.title" | translate }}
              </h2>
            </header>
            <form (ngSubmit)="onSubmit(myForm)" #myForm="ngForm">
              <p>
                {{
                  "pricing-prices.solutions.common.forms.introduction-text1"
                    | translate
                }}
              </p>
              <p>
                {{
                  "pricing-prices.solutions.common.forms.introduction-text2"
                    | translate
                }}
              </p>
              <h3 class="title">
                {{
                  "pricing-prices.solutions.common.forms.first-subtitle"
                    | translate
                }}
              </h3>
              <div class="personal fields">
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.first-name"
                      | translate
                  }}</label>
                  <input
                    type="text"
                    inputmode="text"
                    autocomplete="given-name"
                    required
                    name="givenName"
                    ngModel
                    #givenName="ngModel"
                  />
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div
                    class="error-message"
                    *ngIf="givenName.invalid && givenName.touched"
                  >
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.last-name"
                      | translate
                  }}</label>
                  <input
                    type="text"
                    inputmode="text"
                    autocomplete="last-name"
                    required
                    name="lastName"
                    ngModel
                    #lastName="ngModel"
                  />
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div
                    class="error-message"
                    *ngIf="lastName.invalid && lastName.touched"
                  >
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.email" | translate
                  }}</label>
                  <input
                    type="email"
                    inputmode="email"
                    autocomplete="email"
                    required
                    name="email"
                    ngModel
                    #email="ngModel"
                  />
                  <legend>
                    {{
                      "pricing-prices.solutions.common.forms.email-obs"
                        | translate
                    }}
                  </legend>
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div
                    class="error-message"
                    *ngIf="email.invalid && email.touched"
                  >
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.job" | translate
                  }}</label>
                  <input
                    type="text"
                    inputmode="text"
                    autocomplete="organization-title"
                    required
                    name="job"
                    ngModel
                    #job="ngModel"
                  />
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div class="error-message" *ngIf="job.invalid && job.touched">
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.company" | translate
                  }}</label>
                  <input
                    type="text"
                    inputmode="text"
                    autocomplete="organization"
                    required
                    name="company"
                    ngModel
                    #company="ngModel"
                  />
                  <legend>
                    {{
                      "pricing-prices.solutions.common.forms.company-obs"
                        | translate
                    }}
                  </legend>
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div
                    class="error-message"
                    *ngIf="company.invalid && company.touched"
                  >
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.business-segment"
                      | translate
                  }}</label>
                  <select name="segment" #segment="ngModel" required ngModel>
                    <option disabled selected value="">
                      {{
                        "pricing-prices.solutions.common.forms.select"
                          | translate
                      }}
                    </option>
                    <option value="Payments">
                      {{
                        "pricing-prices.solutions.common.forms.payments-segment"
                          | translate
                      }}
                    </option>
                    <option value="Banks">
                      {{
                        "pricing-prices.solutions.common.forms.banks-segment"
                          | translate
                      }}
                    </option>
                    <option value="Logistics">
                      {{
                        "pricing-prices.solutions.common.forms.logistics-segment"
                          | translate
                      }}
                    </option>
                    <option value="Health">
                      {{
                        "pricing-prices.solutions.common.forms.health-segment"
                          | translate
                      }}
                    </option>
                    <option value="Real State">
                      {{
                        "pricing-prices.solutions.common.forms.real-estate-segment"
                          | translate
                      }}
                    </option>
                    <option value="Energy">
                      {{
                        "pricing-prices.solutions.common.forms.energy-segment"
                          | translate
                      }}
                    </option>
                  </select>
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div
                    class="error-message"
                    *ngIf="segment.invalid && segment.touched"
                  >
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
              </div>
              <h3 class="title">
                {{
                  "pricing-prices.solutions.common.forms.second-subtitle"
                    | translate
                }}
              </h3>
              <div class="package fields">
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.solution" | translate
                  }}</label>
                  <input
                    type="text"
                    name="solution"
                    inputmode="text"
                    class="solution"
                    ngModel
                    #solution="ngModel"
                    [ngModel]="formSolution | translate"
                  />
                </div>
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.package" | translate
                  }}</label>
                  <select name="package" #package="ngModel" required ngModel>
                    <option disabled selected value="">
                      {{
                        "pricing-prices.solutions.common.forms.select"
                          | translate
                      }}
                    </option>
                    <option
                      value="Silver"
                      *ngIf="solutionSelected === this.solutions[0]"
                    >
                      {{ infraCards[0].name | translate }}
                    </option>
                    <option
                      value="Gold"
                      *ngIf="solutionSelected === this.solutions[0]"
                    >
                      {{ infraCards[1].name | translate }}
                    </option>
                    <option
                      value="Diamond"
                      *ngIf="solutionSelected === this.solutions[0]"
                    >
                      {{ infraCards[2].name | translate }}
                    </option>
                    <option
                      value="Digital Wallet"
                      *ngIf="solutionSelected === this.solutions[1]"
                    >
                      {{ whitelabelCards[0].name | translate }}
                    </option>
                    <option
                      value="Exchange"
                      *ngIf="solutionSelected === this.solutions[1]"
                    >
                      {{ whitelabelCards[1].name | translate }}
                    </option>
                    <option
                      value="Diamond"
                      *ngIf="solutionSelected === this.solutions[1]"
                    >
                      {{ whitelabelCards[2].name | translate }}
                    </option>
                    <option
                      value="Diamond"
                      *ngIf="solutionSelected === this.solutions[1]"
                    >
                      {{ whitelabelCards[3].name | translate }}
                    </option>
                    <option
                      value="Tokenizer"
                      *ngIf="solutionSelected === this.solutions[1]"
                    >
                      {{ whitelabelCards[4].name | translate }}
                    </option>
                    <option
                      value="Plus"
                      *ngIf="solutionSelected === this.solutions[2]"
                    >
                      {{ cronosCards[0].name | translate }}
                    </option>
                    <option
                      value="Pro"
                      *ngIf="solutionSelected === this.solutions[2]"
                    >
                      {{ cronosCards[1].name | translate }}
                    </option>
                    <option
                      value="Enterprise"
                      *ngIf="
                        solutionSelected === this.solutions[0] ||
                        solutionSelected === this.solutions[2]
                      "
                    >
                      {{ cronosCards[2].name | translate }}
                    </option>
                  </select>
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div
                    class="error-message"
                    *ngIf="package.invalid && package.touched"
                  >
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
                <div class="input-field">
                  <label>{{
                    "pricing-prices.solutions.common.forms.billing" | translate
                  }}</label>
                  <select name="billing" #billing="ngModel" required ngModel>
                    <option disabled selected value="">
                      {{
                        "pricing-prices.solutions.common.forms.select"
                          | translate
                      }}
                    </option>
                    <option value="Annual">
                      {{
                        "pricing-prices.solutions.common.forms.annual-plan"
                          | translate
                      }}
                    </option>
                    <option value="Monthly">
                      {{
                        "pricing-prices.solutions.common.forms.monthly-plan"
                          | translate
                      }}
                    </option>
                  </select>
                  <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                  <div
                    class="error-message"
                    *ngIf="billing.invalid && billing.touched"
                  >
                    <span>{{
                      "pricing-prices.solutions.common.forms.error-message"
                        | translate
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="terms_accepted"
                    value="true"
                    name="checkbox"
                    ngModel
                    #checkbox="ngModel"
                    required
                  />
                  <span>
                    {{
                      "pricing-prices.solutions.common.forms.terms-conditions-before"
                        | translate
                    }}
                    <strong>
                      <a
                        target="_blank"
                        href="https://axiahomolog.s3.amazonaws.com/Termos+de+uso+-+Axia+-+1.0-4.pdf"
                        >{{
                          "pricing-prices.solutions.common.forms.terms-service"
                            | translate
                        }}</a
                      >
                    </strong>
                    {{
                      "pricing-prices.solutions.common.forms.terms-conditions-middle"
                        | translate
                    }}
                    <strong>
                      <a>{{
                        "pricing-prices.solutions.common.forms.privacy-policy"
                          | translate
                      }}</a>
                    </strong>
                    *
                  </span>
                </label>
                <!-- Checks if the input is invalid and if the user has written something on the input field or focused on it-->
                <div
                  class="error-message"
                  *ngIf="checkbox.invalid && checkbox.touched"
                >
                  <span>{{
                    "pricing-prices.solutions.common.forms.error-message"
                      | translate
                  }}</span>
                </div>
              </div>
              <div class="submit-button-container">
                <input
                  class="submit-button"
                  type="submit"
                  [value]="
                    'pricing-prices.solutions.common.forms.submit' | translate
                  "
                />
              </div>
            </form>
          </div>
          <div class="side-image">
            <img
              src="/assets/images/pricingPage/form-image.png"
              alt="Registration form Image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
