<section class="costumer-page">
  <div class="background">
    <div class="animation-container">
      <p
        class="pre-text"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
        [ngClass]="{ animation: isElementIntersecting }"
      >
        <!-- o ngClass é uma diretiva própria do Angular que vai adicionar ou remover a animation baseado no valor de isElementIntersecting-->
        {{ "customers.animation" | translate }}
      </p>
    </div>
    <div class="title">
      <h2>{{ "customers.title" | translate }}</h2>
    </div>
    <div class="div-button">
      <a (click)="contact()" class="button-efect">
        {{ "customers.button" | translate }}
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 12H5m14 0-4 4m4-4-4-4"
          />
        </svg>
      </a>
    </div>
    <div class="companies">
      <div class="images-slide">
        <div class="images-companies-container">
          <ng-container *ngFor="let company of companies">
            <a [href]="company.link">
              <img
                [id]="company.name"
                [src]="'/assets/images/companies/' + company.name + '-logo.png'"
                [alt]="company.name + ' Logo'"
              />
            </a>
          </ng-container>
        </div>
        <div class="images-companies-container">
          <ng-container *ngFor="let company of companies">
            <a [href]="company.link">
              <img
                [id]="company.name"
                [src]="'/assets/images/companies/' + company.name + '-logo.png'"
                [alt]="company.name + ' Logo'"
              />
            </a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="arrows">
      <button
        class="arrow-right"
        (mouseenter)="startScrollRight()"
        (mouseleave)="stopScrollRight()"
      >
        <img src="/assets/svg/RightChevronSVG.svg" />
      </button>
      <button
        class="arrow-left"
        (mouseenter)="startScrollLeft()"
        (mouseleave)="stopScrollLeft()"
      >
        <img src="/assets/svg/LeftChevronSVG.svg" />
      </button>
      <div class="container">
        <div class="gallery-wrapper">
          <div class="gallery" #gallery>
            <div class="card">
              <div class="depoiment">
                <p>
                  {{ "customers.visionSolutions-depoiment" | translate }}
                </p>
              </div>
              <div class="informations">
                <div class="image-1">
                  <img
                    class="image"
                    src="/assets/images/customers/FilipeChagas.jpeg"
                  />
                </div>
                <div class="name-position">
                  <div class="name">
                    <p>Filipe Chagas</p>
                  </div>
                  <div class="position">
                    <p>
                      {{ "customers.visionSolutions-position" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-dillianz">
              <div class="depoiment">
                <p>
                  {{ "customers.dillianz-depoiment" | translate }}
                </p>
              </div>
              <div class="informations">
                <div class="image-1">
                  <img
                    class="image"
                    src="/assets/images/customers/Schaaron Massoco.jpeg"
                  />
                </div>
                <div class="name-position">
                  <div class="name">
                    <p>Schaaron Massoco</p>
                  </div>
                  <div class="position">
                    <p>{{ "customers.dillianz-position" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-tokeniza">
              <div class="depoiment">
                <p>
                  {{ "customers.tokeniza-depoiment" | translate }}
                </p>
              </div>
              <div class="informations">
                <div class="image-1">
                  <img
                    class="image"
                    src="/assets/images/customers/arthurCoelho.jpg"
                  />
                </div>
                <div class="name-position">
                  <div class="name">
                    <p>Arthur Coelho</p>
                  </div>
                  <div class="position">
                    <p>{{ "customers.tokeniza-position" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-tokenizae">
              <div class="depoiment">
                <p>
                  {{ "customers.tokenizae-depoiment" | translate }}
                </p>
              </div>
              <div class="informations">
                <div class="image-1">
                  <img
                    class="image"
                    src="/assets/images/customers/WalmanPrado.jpeg"
                  />
                </div>
                <div class="name-position">
                  <div class="name">
                    <p>Walman Prado</p>
                  </div>
                  <div class="position">
                    <p>{{ "customers.tokenizae-position" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="depoiment">
                <p>
                  {{ "customers.GEAX-depoiment" | translate }}
                </p>
              </div>
              <div class="informations">
                <div class="image-1">
                  <img
                    class="image"
                    src="/assets/images/customers/JeffersonSilva.jpg"
                  />
                </div>
                <div class="name-position">
                  <div class="name">
                    <p>Jefferson Silva</p>
                  </div>
                  <div class="position">
                    <p>{{ "customers.GEAX-position" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="depoiment">
                <p>
                  {{ "customers.teros-depoiment" | translate }}
                </p>
              </div>
              <div class="informations">
                <div class="image-1">
                  <img
                    class="image"
                    src="/assets/images/customers/JuanPerez.jpeg"
                  />
                </div>
                <div class="name-position">
                  <div class="name">
                    <p>Juan Pérez Ferrés</p>
                  </div>
                  <div class="position">
                    <p>Teros Pricing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
