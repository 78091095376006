import { Component } from '@angular/core';

@Component({
    selector: 'app-international-business',
    templateUrl: './international-business.component.html',
    styleUrls: ['./international-business.component.css']
})
export class InternationalBusinessComponent {

    public isElementIntersecting: boolean = false;

    constructor() { }

    public onIntersection(data: { element: HTMLElement, intersecting: boolean }) {

        this.isElementIntersecting = data.intersecting;

    }

}
