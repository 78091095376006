import { Component, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.css'],
    host: { '(window:resize)': 'checkScreenSize()' }
})
export class HeroComponent implements AfterViewInit {

    // Criacao do Array com todas as empresas que vão aparecer no slide
    public companies = [
        { name: "lottochain", link: "https://thelottochain.com/" },
        { name: "dillianz", link: "https://dillianzbank.com.br" },
        { name: "tokeniza", link: "https://plataforma.tokeniza.com.br/tokenization/crowdfunding" },
        { name: "LG-bank", link: "https://www.lgbank.io/" },
        { name: "vinco", link: "https://vinco.vc/" },
        { name: "clear-ledger", link: "https://clearledger.io/" },
        { name: "zionex", link: "" },
        { name: "zeus-bank", link: "" },
        { name: "globoo", link: "" },
        { name: "tjrr", link: "https://www.tjrr.jus.br/" },
        { name: "b4", link: "https://b4.capital" },
        { name: "dasa", link: "https://dasa.com.br/" },
        { name: "terospricing", link: "https://teros.com.br/br/" },
        { name: "agibank", link: "https://agibank.com.br/" },
    ]

    public mobile: boolean;
    public computer: boolean;

    public ngAfterViewInit(): void {
        this.checkScreenSize();
    }

    public checkScreenSize(): void {
        if (window.innerWidth < 770) {
            this.mobile = true;
            this.computer = false;
        } else {
            this.mobile = false;
            this.computer = true;
        }
    }

}
