<section class="middle-section">
    <div class="applications" appIntersectionObserverAnimation
    (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <h2> {{ "collections-midle.applications-h2" | translate }}</h2>
        <p>{{ "collections-midle.applications-p" | translate }}</p>
        <div class="cards-container">
          <div class="card">
              <div class="animation-container">
                <p class="pre-text" appIntersectionObserverAnimation (isIntersecting)="onIntersection($event)"
                  [ngClass]="{ animation: isElementIntersecting }">
                  {{ "collections-midle.animation-credit" | translate}}
                </p>
              </div>
              <div class="card-content">
                <div class="card-icon-1">
                  <img src="/assets/svg/collectionsPage/search.svg">
                </div>
                <h3>{{ "collections-midle.cardTitleOne" | translate}}</h3>
                <p>{{ "collections-midle.cardTextOne" | translate}}</p>
                <a (click)="contact()" class="button-efect">Learn More</a>
              </div>
          </div>
          <div class="card">
              <div class="animation-container">
                <p class="pre-text" appIntersectionObserverAnimation (isIntersecting)="onIntersection($event)"
                  [ngClass]="{ animation: isElementIntersecting }">
                  {{ "collections-midle.animation-payments" | translate}}
                </p>
              </div>
              <div class="card-content">
                <div class="card-icon-2">
                  <img src="/assets/svg/collectionsPage/coins.svg">
                </div>
                <h3>{{ "collections-midle.cardTitleTwo" | translate}}</h3>
                <p>{{ "collections-midle.cardTextTwo" | translate}}</p>
                <a (click)="contact()" class="button-efect">Learn More</a>
              </div>
          </div>
          <div class="card">
              <div class="animation-container">
                <p class="pre-text" appIntersectionObserverAnimation (isIntersecting)="onIntersection($event)"
                  [ngClass]="{ animation: isElementIntersecting }">
                  {{ "collections-midle.animation-collections" | translate}}
                </p>
              </div>
              <div class="card-content">
                <div class="card-icon-3">
                  <img src="/assets/svg/collectionsPage/target-4.svg">
                </div>
                <h3>{{ "collections-midle.cardTitleThree" | translate}}</h3>
                <p>{{ "collections-midle.cardTextThree" | translate}}</p>
                <a (click)="contact()" class="button-efect">{{ "collections-midle.buttonLearnMore" | translate}}</a>
              </div>
          </div>
        </div>
    </div>
    <div class="top" appIntersectionObserverAnimation
    (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <div class="top-content">
            <h2>{{ "collections-midle.topContentTitle" | translate}}</h2>
            <div class="top-text">
            <p>{{ "collections-midle.topText" | translate}}</p>
                <h4 style="stroke: #fff;">{{ "collections-midle.topTextStroke" | translate}}</h4>
            </div>
        </div>
        <div class="image">
            <img src="/assets/images/collectionsPage/business.png">
        </div>
    </div>
</section>
