<section class="header">
  <header>
    <nav>
      <img
        src="/assets/images/logo-axia.png"
        class="logo-image"
        routerLink="/"
      />
      <ul class="header-items" *ngIf="menuOpen || !mobile">
        <li>
          <a routerLink="/institutional">{{ "header.about" | translate }}</a>
        </li>
        <li>
          <a routerLink="/solutions">{{ "header.solutions" | translate }}</a>
        </li>
        <!-- <li>
          <a routerLink="/collections">{{
            "header.collections" | translate
          }}</a>
        </li> -->
        <li>
          <a routerLink="/pricing">{{ "header.pricing" | translate }}</a>
        </li>
        <li>
          <a
            href="https://axia-digital-solutions.gitbook.io/documentacao-axia-digital-solutions/"
            target="_blank"
            >{{ "header.developers" | translate }}</a
          >
        </li>
        <li>
          <a (click)="contact()">{{ "header.contact" | translate }}</a>
        </li>
      </ul>
      <div class="mobile-layout">
        <div class="language-menu">
          <div class="selected-lang" [attr.id]="selectedLanguage.id">
            <img [src]="selectedLanguage.imageSrc" alt="Language Selected" />
            <img src="/assets/svg/arrow-bottom.svg" alt="Arrow Bottom" />
          </div>
          <ul>
            <li>
              <a
                (click)="
                  changeLanguage({
                    name: 'Brasil',
                    imageSrc: '/assets/svg/country-flags/brazil.svg',
                    id: 'br'
                  })
                "
              >
                <img
                  src="/assets/svg/country-flags/brazil.svg"
                  alt="Brazil Flag"
                />
                <p>Português</p>
              </a>
            </li>
            <li>
              <a
                id="en"
                (click)="
                  changeLanguage({
                    name: 'English',
                    imageSrc: '/assets/svg/country-flags/usa.svg',
                    id: 'en'
                  })
                "
              >
                <img src="/assets/svg/country-flags/usa.svg" alt="USA flag" />
                <p>English</p>
              </a>
            </li>
            <li>
              <a
                id="es"
                (click)="
                  changeLanguage({
                    name: 'Español',
                    imageSrc: '/assets/svg/country-flags/spain.svg',
                    id: 'es'
                  })
                "
              >
                <img
                  src="/assets/svg/country-flags/spain.svg"
                  alt="Spain Flag"
                />
                <p>Español</p>
              </a>
            </li>
          </ul>
        </div>
        <button
          class="mobile-menu"
          (click)="toggleMenu()"
          [ngClass]="{ 'active-button': menuOpen }"
          *ngIf="mobile"
        >
          <img
            src="/assets/images/menu.png"
            alt="Burguer menu"
            *ngIf="!menuOpen"
          />
          <img
            src="/assets/svg/close-button.svg"
            alt="Close button"
            *ngIf="menuOpen"
          />
        </button>
      </div>
    </nav>
  </header>
</section>
