import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    public selectedLanguage = { id: "br", name: "Português", imageSrc: '/assets/svg/country-flags/brazil.svg' };
    public menuOpen: boolean = false;
    public mobile: boolean = false;

    constructor(private translate: TranslateService) { }

    public ngOnInit(): void {
        this.loadInitialLanguage();
        this.checkScreenSize();
    }

    public loadInitialLanguage() {
        const previousLanguageId = localStorage.getItem("languageID");
        if (previousLanguageId) {
            this.setLanguage(previousLanguageId);
        } else {
            this.translate.use('br');
        }
    }

    public contact(): void {
        window.open('https://wa.me/5512997758008', '_blank');
    }

    public setLanguage(id: string) {
        const languages = {
            "br": { id: "br", name: "Português", imageSrc: '/assets/svg/country-flags/brazil.svg' },
            "en": { id: "en", name: "English", imageSrc: '/assets/svg/country-flags/usa.svg' },
            "es": { id: "es", name: "Español", imageSrc: '/assets/svg/country-flags/spain.svg' }
        }
        this.selectedLanguage = languages[id];
        this.translate.use(id);
    }

    public changeLanguage(newLanguage: { name: string, imageSrc: string, id: string }): void {
        this.selectedLanguage = newLanguage;
        localStorage.setItem('languageID', newLanguage.id);
        this.translate.use(newLanguage.id);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event: any) {
        this.checkScreenSize();
    }

    public checkScreenSize(): void {
        this.mobile = window.innerWidth < 920;
    }
    public toggleMenu(): void {
        this.menuOpen = !this.menuOpen;
    }

}
