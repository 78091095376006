<section class="informations">
    <div class="oportunity">
        <div class="oportunity-image">
            <img src="/assets/images/institutionalPage/meeting.png">
        </div>
        <div class="content">
            <h2>{{ "institutional-informations.oportunity-title" | translate}}</h2>
            <div class="oportunity-image-responsive">
                <img src="/assets/images/institutionalPage/meeting.png">
            </div>
            <p class="first">
                {{"institutional-informations.oportunity-first" | translate}}
            </p>
            <div class="environment-button">
                <a (click)="contact()" class="button-efect">
                    {{"institutional-informations.explore-button" | translate}}
                </a>
            </div>
        </div>
    </div>
    <div class="solutions">
        <div class="content">
            <h2>{{"institutional-informations.solutions-title" | translate}}</h2>
            <div class="solutions-image-responsive">
                <img src="/assets/images/institutionalPage/programming.jpg">
            </div>
            <p class="first">
                {{"institutional-informations.solutions-first" | translate}}
            </p>
            <div class="contact-button">
                <a (click)="contact()" class="button-efect">{{"institutional-informations.contact-button" | translate}}</a>
            </div>
        </div>
        <div class="solutions-image">
            <img src="/assets/images/institutionalPage/programming.jpg">
        </div>
    </div>
    <div class="collaboration">
        <div class="text">
            <h2>{{"institutional-informations.collaboration-title" | translate}}</h2>
            <p>
                {{"institutional-informations.collaboration-subtitle" | translate}}
            </p>
            <div class="explore-button">
                <a href="https://midas-homolog.axiadigitalsolutions.net/" target="_blank">{{"institutional-informations.expert-button" | translate}}</a>
            </div>
        </div>
        <div class="collaboration-video">
            <iframe width="450" height="250" src="https://www.youtube.com/embed/rrpgGXN2ImQ?si=T-pj_NCMz_scB9__"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
    <div class="last">
        <div class="values">
            <h2>{{"institutional-informations.last-title" | translate}}</h2>
            <p>
                {{"institutional-informations.last-subtitle" | translate}}
            </p>
        </div>
        <div class="values-row">
            <div class="value">
                <div class="verification">
                    <img src="/assets/svg/institutionalPage/goal.svg">
                </div>
                <h2>{{"institutional-informations.value-title-1" | translate}}</h2>
                <p>
                    {{"institutional-informations.value-subtitle-1" | translate}}
                </p>
            </div>
            <div class="value">
                <div class="verification">
                    <img src="/assets/svg/institutionalPage/goal.svg">
                </div>
                <h2>{{"institutional-informations.value-title-2" | translate}}</h2>
                <p>
                    {{"institutional-informations.value-subtitle-2" | translate}}
                </p>
            </div>
            <div class="value">
                <div class="verification">
                    <img src="/assets/svg/institutionalPage/goal.svg">
                </div>
                <h2>{{"institutional-informations.value-title-3" | translate}}</h2>
                <p>
                    {{"institutional-informations.value-subtitle-3" | translate}}
                </p>
            </div>
            <div class="value">
                <div class="verification">
                    <img src="/assets/svg/institutionalPage/goal.svg">
                </div>
                <h2>{{"institutional-informations.value-title-4" | translate}}</h2>
                <p>
                    {{"institutional-informations.value-subtitle-4" | translate}}
                </p>
            </div>
        </div>
    </div>
</section>