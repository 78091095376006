<section class="products-page">
  <div class="background">
    <div class="products-container">
      <div class="title">
        <div class="animation-container">
          <p
            class="pre-text"
            appIntersectionObserverAnimation
            (isIntersecting)="onIntersection($event)"
            [ngClass]="{ animation: isElementIntersecting }"
          >
            {{ "products.animation" | translate }}
          </p>
        </div>
        <div class="header">
          <h2>
            {{ "products.title" | translate }}
            <span style="color: #93f2ff">{{
              "products.title-detail" | translate
            }}</span>
            {{ "products.title-detail-english" | translate }}
          </h2>
        </div>
        <div class="description">
          {{ "products.subtitle" | translate }}
        </div>
      </div>
      <div class="products-display">
        <div
          appIntersectionObserverAnimation
          alternativeThreshold="0.25"
          (isIntersecting)="onIntersection($event)"
          class="product-row"
        >
          <div class="product">
            <header>
              <div class="product-name">
                <h5>{{ "products.digitalWallet-title" | translate }}</h5>
              </div>
              <div class="product-description">
                <p>
                  {{ "products.digitalWallet-subtitle" | translate }}
                </p>
              </div>
            </header>
            <div class="product-feature-container">
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/ethereum-icon.svg"
                  alt="Ethereum Icon"
                />
                <p>{{ "products.digitalWallet-feature1" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/bitcoin-icon.svg"
                  alt="Bitcoin icon"
                />
                <p>{{ "products.digitalWallet-feature2" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/tron-icon.svg"
                  alt="Tron icon"
                />
                <p>{{ "products.digitalWallet-feature3" | translate }}</p>
              </div>
            </div>
          </div>
          <div class="product">
            <header>
              <div class="product-name">
                <h5>{{ "products.marketplace-title" | translate }}</h5>
              </div>
              <div class="product-description">
                <p>
                  {{ "products.marketplace-subtitle" | translate }}
                </p>
              </div>
            </header>
            <div class="product-features-container">
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/promotions-icon.svg"
                  alt="Promotions Icon"
                />
                <p>{{ "products.marketplace-feature1" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/storage-icon.svg"
                  alt="Storage icon"
                />
                <p>{{ "products.marketplace-feature2" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/payment-card-icon.svg"
                  alt="Payment Card icon"
                />
                <p>{{ "products.marketplace-feature3" | translate }}</p>
              </div>
            </div>
          </div>
          <div class="product">
            <header>
              <div class="product-name">
                <h5>{{ "products.digitalBanking-title" | translate }}</h5>
              </div>
              <div class="product-description">
                <p>
                  {{ "products.digitalBanking-subtitle" | translate }}
                </p>
              </div>
            </header>
            <div class="product-features-container">
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/pix-icon.svg"
                  alt="PIX Icon"
                />
                <p>{{ "products.digitalBanking-feature1" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/bank-transfer-icon.svg"
                  alt="Bank transfer icon"
                />
                <p>{{ "products.digitalBanking-feature2" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/bill-icon.svg"
                  alt="Bill icon"
                />
                <p>{{ "products.digitalBanking-feature3" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          appIntersectionObserverAnimation
          alternativeThreshold="0.25"
          (isIntersecting)="onIntersection($event)"
          class="product-row"
        >
          <div class="product">
            <header>
              <div class="product-name">
                <h5>{{ "products.tokenization-title" | translate }}</h5>
              </div>
              <div class="product-description">
                <p>
                  {{ "products.tokenization-subtitle" | translate }}
                </p>
              </div>
            </header>
            <div class="product-features-container">
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/smart-contract-icon.svg"
                  alt="Smart Contract Icon"
                />
                <p>{{ "products.tokenization-feature1" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/bank-balance-icon.svg"
                  alt="Bank balance icon"
                />
                <p>{{ "products.tokenization-feature2" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/sales-icon.svg"
                  alt="Sales icon"
                />
                <p>{{ "products.tokenization-feature3" | translate }}</p>
              </div>
            </div>
          </div>
          <div class="product">
            <header>
              <div class="product-name">
                <h5>{{ "products.gateway-title" | translate }}</h5>
              </div>
              <div class="product-description">
                <p>{{ "products.gateway-subtitle" | translate }}</p>
              </div>
            </header>
            <div class="product-features-container">
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/transaction-icon.svg"
                  alt="Transaction Icon"
                />
                <p>{{ "products.gateway-feature1" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/transaction-fees-icon.svg"
                  alt="Transaction fees icon"
                />
                <p>{{ "products.gateway-feature2" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/multiple-coins-icon.svg"
                  alt="Coins icon"
                />
                <p>{{ "products.gateway-feature3" | translate }}</p>
              </div>
            </div>
          </div>
          <div class="product">
            <header>
              <div class="product-name">
                <h5>{{ "products.crowdfunding-title" | translate }}</h5>
              </div>
              <div class="product-description">
                <p>
                  {{ "products.crowdfunding-subtitle" | translate }}
                </p>
              </div>
            </header>
            <div class="product-features-container">
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/decentralized-icon.svg"
                  alt="Decentralized Icon"
                />
                <p>{{ "products.crowdfunding-feature1" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/transparency-icon.svg"
                  alt="Transparency icon"
                />
                <p>{{ "products.crowdfunding-feature2" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/regulation-icon.svg"
                  alt="Regulation icon"
                />
                <p>{{ "products.crowdfunding-feature3" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          appIntersectionObserverAnimation
          alternativeThreshold="0.25"
          (isIntersecting)="onIntersection($event)"
          class="product-last-row"
        >
          <div class="product">
            <header>
              <div class="product-name">
                <h5>{{ "products.exchange-title" | translate }}</h5>
              </div>
              <div class="product-description">
                <p>
                  {{ "products.exchange-subtitle" | translate }}
                </p>
              </div>
            </header>
            <div class="product-features-container">
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/coins-icon.svg"
                  alt="Coins Icon"
                />
                <p>{{ "products.exchange-feature1" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/liquidity-icon.svg"
                  alt="Liquidity icon"
                />
                <p>{{ "products.exchange-feature2" | translate }}</p>
              </div>
              <div class="product-feature">
                <img
                  src="/assets/svg/features-products/console-icon.svg"
                  alt="Console icon"
                />
                <p>{{ "products.exchange-feature3" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
