import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions-products',
    templateUrl: './solutions-products.component.html',
    styleUrls: ['./solutions-products.component.css']
})
export class SolutionsProductsComponent implements AfterViewInit {

    public mobile: boolean;
    public computer: boolean;
    public isElementIntersecting: boolean = false;

    constructor() { }

    public ngAfterViewInit(): void {
        this.checkScreenSize();
    }

    public checkScreenSize(): void {
        if (window.screen.width <= 949) {
            this.mobile = true;
            this.computer = false;
        } else {
            this.mobile = false;
            this.computer = true;
        }
    }

    public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {

        if (data.intersecting) {
            // Check if the element should get left or right animation
            const isLeft = data.element.classList.contains('information-container-left') || data.element.classList.contains('image-container-left');
            const isRight = data.element.classList.contains('information-container-right') || data.element.classList.contains('image-container-right');

            if (isLeft && !data.element.classList.contains('animation-left')) {
                data.element.classList.add('animation-left');
            }

            if (isRight && !data.element.classList.contains('animation-right')) {
                data.element.classList.add('animation-right');
            }
        }


    }

}
