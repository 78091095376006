import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-down-section',
  templateUrl: './down-section.component.html',
  styleUrls: ['./down-section.component.css']
})
export class DownSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public contactInfographic(): void {
    window.open('https://wa.me/5512981091039', '_blank');

}

public contactRelease(): void {
  window.open('https://wa.me/5512981091039', '_blank');

}

public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {
 
  if (data.intersecting && !data.element.classList.contains("scrolled")) {
      data.element.classList.add("scrolled");
  }

}

}
