import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-middle-section',
  templateUrl: './middle-section.component.html',
  styleUrls: ['./middle-section.component.css']
})
export class MiddleSectionComponent implements OnInit {
  public isElementIntersecting: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public contact(): void {
    window.open('https://wa.me/5512981091039', '_blank');
}
public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {

  this.isElementIntersecting = data.intersecting;

  if (data.intersecting && !data.element.classList.contains("scrolled") && !data.element.classList.contains("pre-text")) {
      data.element.classList.add("scrolled");
  }

}
}
