<section class="hero">
  <div class="left-section-images">
    <div class="top-icon">
      <img
        class="normal-icon"
        src="/assets/images/hero/icon-hero-2-nobg.png"
        alt="Icon hero"
      />
    </div>
    <div class="middle-images-left">
      <div class="left-icon">
        <img
          class="normal-icon"
          src="/assets/images/hero/icon-hero-9-nobg.png"
          alt="Icon hero"
        />
      </div>
      <div class="right-icon">
        <img
          class="normal-icon"
          src="/assets/images/hero/icon-hero-7-nobg.png"
          alt="Icon hero"
        />
      </div>
    </div>
    <div class="bottom-icon">
      <img
        class="normal-icon"
        src="/assets/images/hero/icon-hero-4-nobg.png"
        alt="Icon hero"
      />
    </div>
  </div>
  <div class="middle-section">
    <h1 class="title">
      {{ "hero.title-firstLine" | translate }} <br />
      {{ "hero.title-secondLine" | translate }}
      <span style="color: #87d2f6; text-shadow: 0 0 3px #87d2f6">{{
        "hero.title-secondLine-highlighted" | translate
      }}</span>
    </h1>
    <div class="subtitle">
      <p>
        {{ "hero.subtitle-firstPart" | translate }}
        <span style="color: rgb(233, 230, 250)">{{
          "hero.subtitle-detail-1" | translate
        }}</span>
        {{ "hero.subtitle-secondPart" | translate }}
        <span style="color: rgb(233, 230, 250)">{{
          "hero.subtitle-detail-2" | translate
        }}</span>
        {{ "hero.subtitle-thirdPart" | translate }}
        {{ "hero.subtitle-forthPart" | translate }}
      </p>
    </div>
    <div class="explore-button">
      <a
        href="https://midas-homolog.axiadigitalsolutions.net/account/login"
        target="_blank"
        >{{ "hero.button" | translate }}</a
      >
    </div>
    <div class="companies">
      <p>{{ "hero.companies" | translate }}</p>
      <div class="images-slide">
        <div class="images-companies-container">
          <ng-container *ngFor="let company of companies">
            <a [href]="company.link">
              <img
                [id]="company.name"
                [src]="'/assets/images/companies/' + company.name + '-logo.png'"
                [alt]="company.name + ' Logo'"
              />
            </a>
          </ng-container>
        </div>
        <div class="images-companies-container">
          <ng-container *ngFor="let company of companies">
            <a [href]="company.link">
              <img
                [id]="company.name"
                [src]="'/assets/images/companies/' + company.name + '-logo.png'"
                [alt]="company.name + ' Logo'"
              />
            </a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="features-container">
      <div class="row">
        <a>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/digital-banking-icon.svg"
            alt="Digital Bank Icon"
          />
          <p>{{ "hero.feature-digitalBanking" | translate }}</p>
        </a>
        <a>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/digital-wallet-icon.svg"
            alt="Digital Wallet Icon"
          />
          <p>{{ "hero.feature-digitalWallet" | translate }}</p>
        </a>
        <a>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/crowdfunding-icon.svg"
            alt="Crowdfunding Icon"
          />
          <p>Crowdfunding</p>
        </a>
        <a *ngIf="mobile">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/tokenization-icon.svg"
            alt="Tokenization icon"
          />
          <p>{{ "hero.feature-tokenization" | translate }}</p>
        </a>
      </div>
      <div class="row">
        <a *ngIf="computer">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/tokenization-icon.svg"
            alt="Tokenization icon"
          />
          <p>{{ "hero.feature-tokenization" | translate }}</p>
        </a>
        <a>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/marketplace-icon.svg"
            alt="Marketplace icon"
          />
          <p>Marketplace</p>
        </a>
        <a>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/exchange-icon.svg"
            alt="Exchange icon"
          />
          <p>Exchange</p>
        </a>
        <a>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            src="/assets/svg/hero-features/gateway-pagamentos-icon.svg"
            alt="Cripto Payments icon"
          />
          <p>{{ "hero.feature-gateway" | translate }}</p>
        </a>
      </div>
    </div>
  </div>
  <div class="right-section-images">
    <div class="top-icon">
      <img
        class="normal-icon"
        src="/assets/images/hero/icon-hero-5-nobg.png"
        alt="Icon hero"
      />
    </div>
    <div class="middle-images-right">
      <div class="left-icon">
        <img
          class="big-icon"
          src="/assets/images/hero/icon-hero-1-nobg.png"
          alt="Icon hero"
        />
      </div>
      <div class="right-icon">
        <img
          class="normal-icon"
          src="/assets/images/hero/icon-hero-3-nobg.png"
          alt="Icon hero"
        />
      </div>
    </div>
    <div class="bottom-icon">
      <img
        class="medium-big-icon"
        src="/assets/images/hero/icon-hero-8-nobg.png"
        alt="Icon hero"
      />
    </div>
  </div>
</section>
