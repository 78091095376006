import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
 
function rotateArray(arr) {
  if (arr.length === 0) return arr;

  const lastElement = arr.pop();
  arr.unshift(lastElement);
  return arr;
}

function rotateArrayLeft(arr) {
  if (arr.length === 0) return arr; 

  const firstElement = arr.shift();
  arr.push(firstElement);
  return arr;
}


@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.css']
})
export class CoverComponent implements OnInit {
  @ViewChild('carouselWrapper') carouselWrapper!: ElementRef;
  isThirdCardMiddle: boolean = true;
  isEvenCardMiddle: boolean = true;
 
  public cards = [
    { text: this.translateService.instant("institutional-cover.card-1") },
    { text: this.translateService.instant("institutional-cover.card-2") },
    { text: this.translateService.instant("institutional-cover.card-3") },
    { text: this.translateService.instant("institutional-cover.card-4") },
    { text: this.translateService.instant("institutional-cover.card-5") }
  ]
  constructor(private renderer: Renderer2,
    private readonly translateService: TranslateService
  ) { }
 
  ngOnInit(): void {
    setInterval(() => {
      this.cards = rotateArray(this.cards);
    }, 4000);
  }

  public moveCarouselRight(): void {
    this.cards = rotateArrayLeft(this.cards);
  }
 
  public moveCarouselLeft(): void {
    this.cards = rotateArray(this.cards);
  }

}