import { Directive, OnDestroy, OnInit, Input, Output, ElementRef, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appIntersectionObserverAnimation]'
})
export class IntersectionObserverAnimationDirective implements OnInit, OnDestroy {

    @Input() debounceTime = 300;  // Permite criar um delay antes de mandar o evento que o elemento está visível (No futuro implementar o debounceTime para otimizar ainda mais a performance do app)
    @Input() threshold = 0.5; // Indica em qual porcentagem da pagina que a funcao de callback deve ser executada

    @Input() alternativeThreshold: number | null = null;

    @Output() isIntersecting = new EventEmitter<{ element: HTMLElement, intersecting: boolean }>();

    private observer: IntersectionObserver;

    constructor(private element: ElementRef<HTMLElement>) { }

    public ngOnInit(): void {

        const effectiveThreshold = (this.alternativeThreshold !== null) ? this.alternativeThreshold : this.threshold;
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.isIntersecting.emit({ element: entry.target as HTMLElement, intersecting: entry.isIntersecting });
                }
            })
        }, {
            threshold: effectiveThreshold,
            root: null,
            rootMargin: '0px'
        });

        this.observer.observe(this.element.nativeElement);

    }

    public ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

}
