<section class="international-business-page">
  <div class="background">
    <div class="animation-container">
      <p
        class="pre-text"
        appIntersectionObserverAnimation
        (isIntersecting)="onIntersection($event)"
        [ngClass]="{ animation: isElementIntersecting }"
      >
        {{ "international.animation" | translate }}
      </p>
    </div>
    <div class="content">
      <div class="banner">
        <div class="digital-globe">
          <img src="/assets/video/spinningGlobe-video.gif"/>
        </div>
      </div>
      <div class="text">
        <div class="title">
          <h1>
            {{ "international.title" | translate }}
            <span style="color: #87d2f6">{{
              "international.title-span" | translate
            }}</span>
          </h1>
        </div>
        <div class="paragraph">
          <p>
            {{ "international.subtitle" | translate }}
          </p>
        </div>
        <div class="transactions">
          <h3 class="subtitle">
            {{ "international.information1-highlighted" | translate }}
          </h3>
          <p class="line">{{ "international.information1" | translate }}</p>
        </div>
        <div class="transactions">
          <h3 class="subtitle">
            {{ "international.information2-highlighted" | translate }}
          </h3>
          <p class="line">{{ "international.information2" | translate }}</p>
        </div>
        <div class="transactions">
          <h3 class="subtitle">
            {{ "international.information3-highlighted" | translate }}
          </h3>
          <p class="line">{{ "international.information3" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
