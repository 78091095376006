import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-informations',
    templateUrl: './informations.component.html',
    styleUrls: ['./informations.component.css']
})
export class InformationsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    public contact(): void {
        window.open('https://wa.me/5512997758008', '_blank');
    }

}
