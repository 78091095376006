<section class="hero">
  <div class="hero-container">
    <div class="hero-columns">
      <div class="left-layout">
        <article>
          <h6>{{ "solutions-hero.subheading" | translate }}</h6>
          <div class="title">
            <h1>
              {{ "solutions-hero.title-first" | translate
              }}<span style="color: #2c99ff">{{
                "solutions-hero.title-span" | translate
              }}</span
              >{{ "solutions-hero.title-after" | translate }}
            </h1>
            <p>
              {{ "solutions-hero.subtitle" | translate }}
            </p>
          </div>
          <div class="explore-button">
            <a
              href="https://midas-homolog.axiadigitalsolutions.net/"
              target="_blank"
              >{{ "solutions-hero.button" | translate }}</a
            >
          </div>
        </article>
      </div>
      <div class="image-container">
        <img
          src="/assets/images/solutionsPage/main-both.png"
          alt="Main Image"
        />
      </div>
    </div>
    <div class="information">
      <p>
        {{ "solutions-hero.platform-integration" | translate }}
      </p>
      <div class="solutions-container">
        <ng-container class="ng-container" *ngFor="let solution of solutions">
          <img [src]="solution.url" [alt]="solution.name + 'Logo'" />
        </ng-container>
      </div>
    </div>
  </div>
</section>
