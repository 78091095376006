<section class="last">
<<<<<<< HEAD
    <div class="microservices" appIntersectionObserverAnimation
    (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <h2>{{ "collections-last.microservicesTitle" | translate}}</h2>
        <p>{{ "collections-last.microservicesText" | translate}}</p>
            <div class="row">
            <div class="microservices-image">
                <img src="/assets/images/collectionsPage/microservices.png">
            </div>
            <div class="microservices-content">
                <div class="box">
                    <h3>{{ "collections-last.boxTitleOne" | translate}}</h3>
                    <p>{{ "collections-last.boxTextOne" | translate}}</p>
                </div>
                <div class="box">
                    <h3>{{ "collections-last.boxTitleTwo" | translate}}</h3>
                    <p>{{ "collections-last.boxTitleTwo" | translate}}</p>
                </div>
                <div class="box">
                    <h3>{{ "collections-last.boxTitleThree" | translate}}</h3>
                    <p>{{ "collections-last.boxTextThree" | translate}}</p>
                </div>
                <div class="box">
                    <h3>{{ "collections-last.boxTitleFour" | translate}}</h3>
                    <p>{{ "collections-last.boxTextFour" | translate}}</p>
                </div>
                <div class="box">
                    <p>{{ "collections-last.boxTextFive" | translate}}</p>
                </div>
            </div>
        </div>
    </div>
=======
>>>>>>> 7d177fbf1318114a3f96914033ab7dc6b78a13b0
    <div class="deploy" appIntersectionObserverAnimation
    (isIntersecting)="onIntersection($event)"  alternativeThreshold="0.1">
        <h2>{{ "collections-last.deployTitle" | translate}}</h2>
        <div class="deploy-cards">
            <div class="card">
                <h4>{{ "collections-last.cardTitleOne" | translate}}</h4>
                <p>{{ "collections-last.cardTextOne" | translate}}</p>
            </div>
            <div class="card">
                <h4>{{ "collections-last.cardTitleTwo" | translate}}</h4>
                <p>{{ "collections-last.cardTextTwo" | translate}}</p>
            </div>
            <div class="card">
                <h4>{{ "collections-last.cardTitleThree" | translate}}</h4>
                <p>{{ "collections-last.cardTextThree" | translate}}</p>
            </div>
        </div>
    </div>
</section>