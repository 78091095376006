import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    constructor(
        private readonly translate: TranslateService
    ) {
        const browserLang: string = this.translate.getBrowserLang();

        if (browserLang.match(/pt/)) {
            this.translate.use('pt-br');
        } else if (browserLang.match(/es/)) {
            this.translate.use('es');
        }else {
            this.translate.use('en');
        }
    }

}
