import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeroComponent } from './pages/home/components/hero/hero.component';
import { ProductsComponent } from './pages/home/components/products/products.component';
import { CustomerComponent } from './pages/home/components/customer/customer.component';
import { InternationalBusinessComponent } from './pages/home/components/international-business/international-business.component';
import { HowWeWorkComponent } from './pages/home/components/how-we-work/how-we-work.component';
import { IntersectionObserverAnimationDirective } from './intersection-observer-animation.directive';
import { HomeComponent } from './pages/home/home.component';
import { InstitutionalComponent } from './pages/institutional/institutional.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { SolutionsHeroComponent } from './pages/solutions/components/solutions-hero/solutions-hero.component';
import { CoverComponent } from './pages/institutional/components/cover/cover.component';
import { InformationsComponent } from './pages/institutional/components/informations/informations.component';
import { FeaturesComponent } from './pages/institutional/features/features.component';
import { SolutionsProductsComponent } from './pages/solutions/components/solutions-products/solutions-products.component';
import { SolutionsTestComponent } from './pages/solutions/components/solutions-test/solutions-test.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PricesComponent } from './pages/pricing/components/prices/prices.component';
import { QuestionsComponent } from './pages/pricing/components/questions/questions.component';
import { CollectionsComponent } from './pages/collections/collections.component';
import { TopComponent } from './pages/collections/components/top/top.component';
import { MiddleSectionComponent } from './pages/collections/components/middle-section/middle-section.component';
import { DownSectionComponent } from './pages/collections/components/down-section/down-section.component';
import { LastSectionComponent } from './pages/collections/components/last-section/last-section.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        CustomerComponent,
        FooterComponent,
        HeroComponent,
        ProductsComponent,
        InternationalBusinessComponent,
        HowWeWorkComponent,
        IntersectionObserverAnimationDirective,
        HomeComponent,
        InstitutionalComponent,
        SolutionsComponent,
        SolutionsHeroComponent,
        CoverComponent,
        InformationsComponent,
        FeaturesComponent,
        SolutionsProductsComponent,
        SolutionsTestComponent,
        PricingComponent,
        PricesComponent,
        QuestionsComponent,
        CollectionsComponent,
        TopComponent,
        MiddleSectionComponent,
        DownSectionComponent,
        LastSectionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: 'br',
        }),
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
