import { Component } from '@angular/core';

@Component({
    selector: 'app-how-we-work',
    templateUrl: './how-we-work.component.html',
    styleUrls: ['./how-we-work.component.css']
})
export class HowWeWorkComponent {

    constructor() { }

    public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {

        if (data.intersecting && !data.element.classList.contains("scrolled")) {
            data.element.classList.add("scrolled");
        }

    }

    public contact(): void {
        window.open('https://wa.me/5512997758008', '_blank');
    }

}
