import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.css']
})
export class QuestionsComponent {

    public questions = [
        { title: this.translateService.instant("pricing-questions.getting-started.first-question"), answer: this.translateService.instant("pricing-questions.getting-started.first-answer"), open: false, color: '#f0f3f8' },
        { title: this.translateService.instant("pricing-questions.getting-started.second-question"), answer: this.translateService.instant("pricing-questions.getting-started.second-answer"), open: false, color: '#f0f3f8' },
        { title: this.translateService.instant("pricing-questions.getting-started.third-question"), answer: this.translateService.instant("pricing-questions.getting-started.third-answer"), open: false, color: '#f0f3f8' },
        { title: this.translateService.instant("pricing-questions.getting-started.forth-question"), answer: this.translateService.instant("pricing-questions.getting-started.forth-answer"), open: false, color: '#f0f3f8' },
        { title: this.translateService.instant("pricing-questions.getting-started.fifth-question"), answer: this.translateService.instant("pricing-questions.getting-started.fifth-answer"), open: false, color: '#f0f3f8' },
        { title: this.translateService.instant("pricing-questions.getting-started.sixth-question"), answer: this.translateService.instant("pricing-questions.getting-started.sixth-answer"), open: false, color: '#f0f3f8' },
    ]

    constructor(private readonly translateService: TranslateService) { }

    public toggleAnswer(index: number): void {
        this.questions[index].open = !this.questions[index].open;
        this.questions[index].color = this.questions[index].color === '#f0f3f8' ? "#184fdb" : "#f0f3f8";
    }

    public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {

        if (data.intersecting && !data.element.classList.contains("animation")) {
            data.element.classList.add("animation");
        }

    }

}
