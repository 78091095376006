import { Component } from '@angular/core';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent {

    public isElementIntersecting: boolean = false;

    constructor() { }

    public onIntersection(data: { element: HTMLElement, intersecting: boolean }): void {

        this.isElementIntersecting = data.intersecting;

        if (data.intersecting && !data.element.classList.contains("scrolled") && !data.element.classList.contains("pre-text")) {
            data.element.classList.add("scrolled");
        }

    }


}
